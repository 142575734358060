import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";

import GetStarted from "../../Components/GetStarted/GetStarted";

import {
    Cat1,
    Cat2,
    Cat3,
    Cat4,
    Cat5,
    Cat6,
    Cat7,
    Cat8,
    Person1,
    Person2,
    Person3,
    Person4,
    Person5,
    Person6,
    Person7,
    Person8,
} from "../../assets/images";

let persons = [
  {
    img: Person1,
    bg: "#643D46",
  },
  {
    img: Person2,
    bg: "#C56320",
  },
  {
    img: Person3,
    bg: "#603D10",
  },
  {
    img: Person4,
    bg: "#501068",
  },
  {
    img: Person5,
    bg: "#126B8E",
  },
  {
    img: Person6,
    bg: "#1B8463",
  },
  {
    img: Person7,
    bg: "#730000",
  },
  {
    img: Person8,
    bg: "#006D6B",
  },
];

let categories = [
  {
    name: "Household Help",
    img: Cat1,
  },
  {
    name: "Healthcare Appointment",
    img: Cat2,
  },
  {
    name: "Salon/SPA Appointment",
    img: Cat3,
  },
  {
    name: "Grocery Runs",
    img: Cat4,
  },
  {
    name: "Travel Companions",
    img: Cat5,
  },
  {
    name: "Personal Driver",
    img: Cat6,
  },
  {
    name: "Lively-hood & Exercise",
    img: Cat7,
  },
  {
    name: "Shopping",
    img: Cat8,
  },
];

function Faqs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="FaqsMain">
      <div className="banner-sec mb-2">
        <div className="container d-flex align-items-center justify-content-center h-100">
          <div className="heading">
            <h1>Faqs</h1>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry?
            </Accordion.Header>
            <Accordion.Body>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry?
            </Accordion.Header>
            <Accordion.Body>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry?
            </Accordion.Header>
            <Accordion.Body>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry?
            </Accordion.Header>
            <Accordion.Body>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry?
            </Accordion.Header>
            <Accordion.Body>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <GetStarted />
    </div>
  );
}

export default Faqs;
