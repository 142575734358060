import React, { useState } from "react";

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  createPaymentIntent
} from "../../../redux/userSlice/actions";
import { getUserDetail } from "../../../redux/authSlice/actions";

const stripePromise = loadStripe(
  "pk_test_51Q7gszFM2Z7VvSwOVWs1S09IlCM56SMvlqHn7ENKJ1jCexAZSJaZmZd2zWuGVtlJiZa3EG6nluQaoK1LfCvsCAxZ00ejePKLak"
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  let [dollar, setDollar] = useState(dollarsToCents(10));
  let [credits, setCredits] = useState(10);

  let user = useSelector((state) => state.auth.user);

  let dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setPaymentMessage(error.message);
      setIsProcessing(false);
    } else {
      dispatch(
        createPaymentIntent({
          paymentData: { amount: dollar, currency: "usd" },
          user,
        })
      ).then(async (res) => {
        if (res.payload?.clientSecret) {
          const { clientSecret } = res.payload;

          const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
          });

          if (confirmPayment.error) {
            setPaymentMessage("Payment failed. Please try again.");
            setIsProcessing(false);
          } else {
            setPaymentMessage("Payment successful!");
            setCredits(10);
            setDollar(dollarsToCents(10));
            setIsProcessing(false);
            dispatch(getUserDetail())
          }
        }
      });
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#333",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#888",
        },
      },
      invalid: {
        iconColor: "#ff3860",
        color: "#ff3860",
      },
    },
    hidePostalCode: true, // Hide postal code if you don't want it
  };

  function dollarsToCents(dollars) {
    if (typeof dollars !== "number" || isNaN(dollars)) {
      throw new Error("Input must be a valid number");
    }
    return Math.round(dollars * 100);
  }

  const setUsd = (val) => {
    if (val == 0) {
      setCredits(10);
      setDollar(dollarsToCents(10));
    } else {
      setCredits(val);
      setDollar(dollarsToCents(Number(val)));
    }
  };

  return (
    <>
      <div className="selectAmount">
        <div className="text-center">
          <label for="customRange3" class="form-label">
            Purchase Credit
          </label>
          <input
            onChange={(e) => setUsd(e.target.value)}
            value={credits}
            type="range"
            class="form-range"
            min="10"
            max="5000"
            step="10"
            id="customRange3"
          />
        </div>

        <div>
          for <b>{credits} credit</b> you will be charged <b>${credits}</b>
        </div>
        <div className="mt-5">
          <div className="inputDiv">
            <lable>Add custom amount</lable>
            <input
              type="number"
              value={credits}
              onChange={(e) => setUsd(e.target.value)}
            />
          </div>
        </div>
      </div>

      <form
        className="mt-5"
        onSubmit={handleSubmit}
        style={{ maxWidth: "100%", margin: "auto" }}
      >
        <h2>Enter Payment Details</h2>
        <div
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "6px",
            marginBottom: "10px",
          }}
        >
          <CardElement options={cardElementOptions} />
        </div>

        <div className="btn-div d-flex justify-content-center mt-4">
          <button className="m-auto" type="submit" disabled={isProcessing}>
            {isProcessing ? "Processing..." : `Pay $${credits}`}
          </button>
        </div>
        {paymentMessage && <p>{paymentMessage}</p>}
      </form>
    </>
  );
};

function PurchaseCredits() {
  return (
    <div className="shadow p-3">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}

export default PurchaseCredits;
