import React, { useEffect, useState } from "react";
import { Badge, Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cancelJob, getAllApplications } from "../../../redux/applicationSlice/actions";

function JobsInProgress() {
  const dispatch = useDispatch();
  const allApplications = useSelector((state) => state.applications.allApplications);
  const [searchQuery, setSearchQuery] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(getAllApplications({ status: "inProgress" })).then((res) => {
      if (res?.payload?.status === "success") {
        // Handle success if needed
      }
    });
  }, [dispatch]);

  const cancelJobRequest = (a) => {
    dispatch(cancelJob({ job: a?.job?._id, companion: a?.companion?._id })).then((res) => {
      if (res?.payload?.status === "success") {
        dispatch(getAllApplications({ status: "inProgress" }));
      }
    });
  };

  // Filter applications based on search query
  const filteredApplications = allApplications?.filter((application) =>
    application?.job?.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate current applications based on the current page
  const indexOfLastApplication = currentPage * itemsPerPage;
  const indexOfFirstApplication = indexOfLastApplication - itemsPerPage;
  const currentApplications = filteredApplications?.slice(indexOfFirstApplication, indexOfLastApplication);

  // Total pages
  const totalPages = Math.ceil((filteredApplications?.length || 0) / itemsPerPage);

  return (
    <div className="jobHistoryMain p-3">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="searchInput">
          <input
            type="text"
            placeholder="Search here ..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="searchIconBtn">
            <button type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="#fff"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
        </div>
      </form>
      <div className="tableDiv">
        <Table bordered hover>
          <thead>
            <tr>
              <th>S. no</th>
              <th>Job Title</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentApplications?.map((a, i) => (
              <tr key={i}>
                <td className="text-center">{indexOfFirstApplication + i + 1}</td>
                <td>{a.job?.title}</td>
                <td>${a.amount}</td>
                <td className="text-center">
                  {a.status === "applied" ? (
                    <Badge pill bg="primary">
                      Applied
                    </Badge>
                  ) : a.status === "rejected" ? (
                    <Badge pill bg="danger">
                      Rejected
                    </Badge>
                  ) : a.status === "inProgress" ? (
                    <Badge pill bg="secondary">
                      In Progress
                    </Badge>
                  ) : (
                    <Badge pill bg="success">
                      Finished
                    </Badge>
                  )}
                </td>
                <td>{a.createdAt.split("T")[0]}</td>
                <td>
                  <div className="btn-div">
                    <button onClick={() => cancelJobRequest(a)}>Cancel Job</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-end mt-3">
        <Pagination className="custom-pagination">
          <Pagination.Prev
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
}

export default JobsInProgress;
