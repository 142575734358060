import React, { useEffect, useState } from "react";
import { Col, Form, Pagination, Row } from "react-bootstrap";
import JobCard from "../../Components/GetStarted/JobCard";
import GetStarted from "../../Components/GetStarted/GetStarted";
import { useDispatch, useSelector } from "react-redux";
import { getAllFrontendJobs } from "../../redux/jobSlice/actions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Cat1,
  Cat2,
  Cat3,
  Cat4,
  Cat5,
  Cat6,
  Cat7,
  Cat8,
  Person1,
  Person2,
  Person3,
  Person4,
  Person5,
  Person6,
  Person7,
  Person8,
} from "../../assets/images";
import "./jobDetail.scss";

const persons = [
  { img: Person1, bg: "#643D46" },
  { img: Person2, bg: "#C56320" },
  { img: Person3, bg: "#603D10" },
  { img: Person4, bg: "#501068" },
  { img: Person5, bg: "#126B8E" },
  { img: Person6, bg: "#1B8463" },
  { img: Person7, bg: "#730000" },
  { img: Person8, bg: "#006D6B" },
];

const categories = [
  { name: "Household Help", img: Cat1 },
  { name: "Healthcare Appointment", img: Cat2 },
  { name: "Salon/SPA Appointment", img: Cat3 },
  { name: "Grocery Runs", img: Cat4 },
  { name: "Travel Companions", img: Cat5 },
  { name: "Personal Driver", img: Cat6 },
  { name: "Lively-hood & Exercise", img: Cat7 },
  { name: "Shopping", img: Cat8 },
];

function Jobs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allJobs = useSelector((state) => state.job.allJobs);
  const totalPage = useSelector((state) => state.job.totalPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null); // Default to null for "All Categories"

  const limit = 12;

  // Fetch jobs based on current page and selected category
  useEffect(() => {
    console.log("useeffect");
    
    dispatch(getAllFrontendJobs({ category: selectedCategory, page: currentPage, limit })); // Send category name
  }, [dispatch, currentPage, selectedCategory]);

  const handleCategoryChange = (categoryName) => {
    setSelectedCategory(categoryName);
    setCurrentPage(1); // Reset to page 1 when selecting a category
    navigate(`/jobs?page=1&categories=${categoryName}`); // Navigate to the first page with selected category
  };

  const filteredJobs = allJobs?.filter((job) => {
    if (selectedCategory === null) return true; // If no category is selected, show all jobs
    return job.category === selectedCategory; // Filter based on category name
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`/jobs?page=${page}&categories=${selectedCategory}`); // Navigate to the selected page with the current category
  };

  const paginationItems = [];
  for (let number = 1; number <= totalPage; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="JobsMain">
      <div className="banner-sec mb-2">
        <div className="container d-flex align-items-center justify-content-center h-100">
          <div className="heading">
            <h1>All Jobs</h1>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <Row>
          <Col md={3}>
            <div className="filterDiv shadow-lg card p-3">
              <div className="heading">
                <h5>Job Categories</h5>
              </div>
              <hr />
              <div className="selectDiv">
                <div className="mb-3">
                  <Form.Check
                    type={"radio"}
                    id={`all`}
                    label={`All Category`}
                    checked={selectedCategory === null} // Check if "All Category" is selected
                    onChange={() => handleCategoryChange(null)} // Select all categories
                  />
                </div>

                {categories.map((category, i) => (
                  <div key={i} className="mb-3">
                    <Form.Check
                      type={"radio"}
                      id={`checkbox-${i}`}
                      label={`${category.name}`}
                      checked={selectedCategory === category.name}
                      onChange={() => handleCategoryChange(category.name)} // Use category name
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col md={9}>
            <Row className="row-gap-4">
              {filteredJobs?.length > 0 ? (
                filteredJobs.map((job, i) => (
                  <Col md={4} key={i}>
                    <JobCard
                      job={job}
                      obj={persons[Math.floor(Math.random() * 8)]}
                    />
                  </Col>
                ))
              ) : (
                <Col md={12}>
                  <div className="text-center mt-4">
                    <h5>Jobs not available for this category.</h5>
                  </div>
                </Col>
              )}
            </Row>
            <Pagination className="justify-content-center mt-4 pagination-custom">
              {paginationItems}
            </Pagination>
          </Col>
        </Row>
      </div>
      <GetStarted />
    </div>
  );
}

export default Jobs;
