import React, { useEffect, useState } from 'react'

import './SubscriptionScreen.scss'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import StripeModal from '../../Components/StripeModal/StripeModal'

function SubcriptionScreen() {

  let { user } = useSelector((state) => state.auth)


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className='subscriptionScreenMain'>
      <Container>
        <h1 class="text-center pricing-table-title">Unlock Exclusive Benefits</h1>
        <h5 class="text-center pricing-table-subtitle">Subscribe Now to Get the Most Out of Our Services</h5>
        <div class="row justify-content-center">

          {user?.role === "loved-one" ?

            <div class="col-md-4">
              <div class="card pricing-card pricing-card-highlighted  pricing-plan-pro">
                <div class="card-body">
                  <i class="fas fa-unlock-alt pricing-plan-icon"></i>
                  <h3 class="pricing-plan-cost ml-auto">$99/One Time Startup Fee</h3>
                  <ul class="pricing-plan-features">
                    <li>50 Free Credits</li>
                    <li>New User Background Check</li>
                    <li>Unlimited Job Postings</li>
                  </ul>
                  <div className='btn-div'>
                    {user?.subscribed ?
                      <button>Already Subscribed</button>
                      :
                      <button onClick={handleShow}>Purchase</button>
                    }
                  </div>
                </div>
              </div>
            </div>
            :
            <div class="col-md-4">
              <div class="card pricing-card pricing-card-highlighted  pricing-plan-pro">
                <div class="card-body">
                  <i class="fas fa-unlock-alt pricing-plan-icon"></i>
                  <h3 class="pricing-plan-cost ml-auto">$29/One Time Startup Fee</h3>
                  <ul class="pricing-plan-features">
                    <li>New User Background Check</li>
                    <li>Apply to Unlimited Jobs</li>
                  </ul>
                  <div className='btn-div'>
                    {user?.subscribed ?
                      <button>Already Subscribed</button>
                      :
                      <button onClick={handleShow}>Purchase</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </Container>

      <StripeModal show={show} handleClose={handleClose} />
    </div>
  )
}

export default SubcriptionScreen