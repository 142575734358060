import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  getUserDetail,
  updateUserData,
  upload,
} from "../../../redux/authSlice/actions";
import { useDispatch, useSelector } from "react-redux";

function IdentityVerify() {
  let [idFront, setIdFront] = useState("");
  let [idBack, setIdBack] = useState("");

  let user = useSelector((state) => state.auth.user);

  let dispatch = useDispatch();

  const uploadImage = (e, name) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    dispatch(upload({ payload: formData, upload: true })).then((res) => {
      if (res.payload?.status === "success") {
        let image = res.payload?.data?.url;
        if (name === "front") {
          setIdFront(image);
        } else {
          setIdBack(image);
        }
      }
    });
  };

  const updateUser = (e) => {
    e.preventDefault();
    let data = {
      id_front: idFront,
      id_back: idBack,
    };
    dispatch(updateUserData({ payload: data })).then((res) => {
      if (res.payload?.status === "success") {
        dispatch(getUserDetail());
      }
    });
  };

  useEffect(() => {
    if (user) {
      setIdBack(user?.id_back);
      setIdFront(user?.id_front);
    }
  }, [user]);

  return (
    <div className="idenityVerify shadow p-3">
      <div className="heading text-center">
        <h5>Upload Your Identity Card Pictures</h5>
      </div>

      <div className="UploadDivMain">
        <Row>
          <Col md={7}>
            <div
              className="uploadView"
              style={idFront ? { backgroundImage: `url("${idFront}")` } : {}}
            >
              {!user?.id_front && (
                <div className="heading">
                  <h6>
                    Upload File
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-cloud-arrow-up-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0z" />
                    </svg>
                  </h6>
                </div>
              )}
              {!user?.id_front && (
                <div className="fileInput">
                  <input
                    type="file"
                    onChange={(e) => uploadImage(e, "front")}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col md={5}>
            {!user?.id_front ? (
              <div className="textDiv">
                <div className="heading text-center">
                  <h5>
                    Upload Front Side
                    <br /> of your ID
                  </h5>
                </div>
              </div>
            ) : (
              <div className="textDiv">
                <div className="btn-div">
                  <button>
                    Re-upload
                    <div className="fileInput">
                      <input
                        type="file"
                        onChange={(e) => uploadImage(e, "front")}
                      />
                    </div>
                  </button>
                </div>
              </div>
            )}
          </Col>
          <Col md={7}>
            <div
              className="uploadView mt-3"
              style={idBack ? { backgroundImage: `url("${idBack}")` } : {}}
            >
              {!user?.id_back && (
                <div className="heading">
                  <h6>
                    Upload File
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-cloud-arrow-up-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0z" />
                    </svg>
                  </h6>
                </div>
              )}

              {!user?.id_back && (
                <div className="fileInput">
                  <input type="file" onChange={(e) => uploadImage(e, "back")} />
                </div>
              )}
            </div>
          </Col>
          <Col md={5}>
            {!user?.id_back ? (
              <div className="textDiv">
                <div className="heading text-center">
                  <h5>
                    Upload Back Side
                    <br /> of your ID
                  </h5>
                </div>
              </div>
            ) : (
              <div className="textDiv">
                <div className="btn-div">
                  <button>
                    Re-upload
                    <div className="fileInput">
                      <input
                        type="file"
                        onChange={(e) => uploadImage(e, "back")}
                      />
                    </div>
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>

      <div className="btn-div">
        <button className="primary" onClick={updateUser}>
          Submit
        </button>
      </div>
    </div>
  );
}

export default IdentityVerify;
