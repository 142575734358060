import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import GetStarted from "../../Components/GetStarted/GetStarted";
import { applyJob } from "../../redux/applicationSlice/actions";
import "./jobDetail.scss";
function JobDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  let dispatch = useDispatch();

  const [formData, setFormData] = useState({
    description: "",
    amount: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitProposal = (e) => {
    e.preventDefault();
    let obj = formData;
    obj.createdBy = location.state?.createdBy?._id;
    obj.job = location.state?._id;

    dispatch(applyJob({ payload: obj })).then((res) => {
      if (res.payload?.status === "success") {
        navigate(-1);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="JobsDetailMain">
      <div className="container pt-5 pb-5">
        <h3>{location.state?.title}</h3>

        <div className="postdate-and-address">
          <p>Posted: {location.state.createdAt?.split("T")[0]}</p>
          <p className="address">
            <div className="address-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                aria-hidden="true"
                viewBox="0 0 24 24"
                role="img"
              >
                <path
                  vector-effect="non-scaling-stroke"
                  stroke="var(--icon-color, #001e00)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                  d="M12 10.5a2.1 2.1 0 100-4.2 2.1 2.1 0 000 4.2z"
                ></path>
                <path
                  vector-effect="non-scaling-stroke"
                  stroke="var(--icon-color, #001e00)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                  d="M17.4 8.4C17.4 5.4 15 3 12 3 9 3 6.6 5.4 6.6 8.4c0 1.3.5 2.4 1.2 3.4C9 13.2 12 18 12 18s3-4.8 4.1-6.3c.7-.9 1.3-2.1 1.3-3.3zM16 18c2.4.3 4 .8 4 1.4 0 .9-3.6 1.6-8 1.6s-8-.7-8-1.6c0-.6 1.6-1.1 4-1.4"
                ></path>
              </svg>
            </div>
            {location.state.address || "-"}
          </p>
        </div>

        <p className="descripton">{location.state?.description}</p>

        <div className="time-duration-div">
          <p className="timing">
            <div className="timing-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                aria-hidden="true"
                viewBox="0 0 24 24"
                role="img"
              >
                <path
                  vector-effect="non-scaling-stroke"
                  stroke="var(--icon-color, #001e00)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M12 21a9 9 0 100-18 9 9 0 000 18z"
                ></path>
                <path
                  vector-effect="non-scaling-stroke"
                  stroke="var(--icon-color, #001e00)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M16.24 16.24L12 12V6"
                ></path>
              </svg>
            </div>
            {location.state.type || "-"}
          </p>
          <p className="timing">
            <div className="timing-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                role="img"
              >
                <path
                  fill="none"
                  vector-effect="non-scaling-stroke"
                  stroke="var(--icon-color, #001e00)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M18.3 20.75H5.7a2.81 2.81 0 01-2.7-3v-10a2.81 2.81 0 012.7-3h12.6a2.81 2.81 0 012.7 3v10a2.87 2.87 0 01-2.7 3zM7.5 6.25v-3m9 3v-3"
                ></path>
                <path
                  fill="var(--icon-color, #001e00)"
                  d="M10.8 15v1.15l-4.8-2v-1.29l4.79-2V12L7 13.5zm1.44-3a2.51 2.51 0 01.33-1.3 2.27 2.27 0 011-.89 3.68 3.68 0 011.6-.31 3.18 3.18 0 011.28.25 2.09 2.09 0 01.94.74 1.84 1.84 0 01.35 1.14 1.69 1.69 0 01-1.16 1.66 1.86 1.86 0 011.09.7 2.12 2.12 0 01.33 1.2 2.07 2.07 0 01-.76 1.73 3.17 3.17 0 01-2.05.61 3.36 3.36 0 01-2.33-.73 2.7 2.7 0 01-.86-2.09h1.25a1.71 1.71 0 00.47 1.29 1.81 1.81 0 001.37.45 1.91 1.91 0 001.23-.32 1.16 1.16 0 00.38-.95c0-.86-.52-1.3-1.56-1.3h-.7v-1h.65a1.55 1.55 0 001-.28 1 1 0 00.35-.85 1 1 0 00-.33-.81 1.35 1.35 0 00-1-.32 1.68 1.68 0 00-1.23.39 1.39 1.39 0 00-.38 1z"
                ></path>
              </svg>
            </div>
            {location.state.date?.split("T")[0] || "-"}
          </p>
          <p className="timing">
            <div className="timing-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                aria-hidden="true"
                viewBox="0 0 24 24"
                role="img"
              >
                <path
                  vector-effect="non-scaling-stroke"
                  stroke="var(--icon-color, #001e00)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                  d="M12 21a8 8 0 100-16 8 8 0 000 16z"
                ></path>
                <path
                  vector-effect="non-scaling-stroke"
                  stroke="var(--icon-color, #001e00)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                  d="M17.3 13l-5.3-.1V7.6M10 3h4M6.8 6.8L5.2 5.2M4 6.5L6.5 4"
                ></path>
              </svg>
            </div>
            ${location.state.budget || "-"}
          </p>
        </div>
        <form onSubmit={(e) => submitProposal(e)} className="pt-5">
          {/* <h6>Job Description</h6>
            <p>{job?.description}</p> */}
          <div className="inputDiv">
            <label>Amount</label>
            <input
              required
              type="number"
              placeholder="Amount"
              value={formData.amount}
              onChange={handleInputChange}
              name="amount"
            />
          </div>
          <div className="inputDiv mt-3">
            <label>Cover Letter</label>
            <textarea
              required
              rows={5}
              value={formData.description}
              onChange={handleInputChange}
              name="description"
            ></textarea>
          </div>
          <div className="btn-div d-flex mt-3">
            <button
              type="button"
              className="secondary"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button type="submit" className="primary">
              Bid
            </button>
          </div>
        </form>
      </div>
      <GetStarted />
    </div>
  );
}

export default JobDetail;
