import React, { useEffect, useState } from "react";
import { Badge, Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import ReviewModal from "../../../Components/ReviewModal/ReviewModal";
import { getAllApplications } from "../../../redux/applicationSlice/actions";
import { completeUserJob, getPostedJobs } from "../../../redux/jobSlice/actions";

function OngoingJobs() {
  const dispatch = useDispatch();
  const postedJobs = useSelector((state) => state.job.postedJobs);
  const navigate = useNavigate();

  const allApplications = useSelector((state) => state.applications.allApplications);
  const user = useSelector((state) => state.auth.user);

  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const getData = () => {
    if (user) {
      if (user?.role === "loved-one") {
        dispatch(getPostedJobs()).then((res) => {
          if (res?.payload?.status === "success") {
            // console.log(res.payload)
          }
        });
      } else {
        dispatch(getAllApplications({})).then((res) => {
          if (res?.payload?.status === "success") {
            // console.log(res.payload)
          }
        });
      }
    }
  };

  useEffect(() => {
    getData();
  }, [user]);

  const completeJob = (a) => {
    let obj = a?.applications?.find((app) => app.status === "inProgress");
    dispatch(completeUserJob({ job: a._id, companion: obj._id })).then(
      (res) => {
        if (res?.payload?.status === "success") {
          dispatch(getPostedJobs());
        }
      }
    );
  };

  // Filter jobs based on search query
  const filteredJobs = postedJobs?.filter((job) =>
    job?.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate current jobs based on the current page
  const indexOfLastJob = currentPage * itemsPerPage;
  const indexOfFirstJob = indexOfLastJob - itemsPerPage;
  const currentJobs = filteredJobs?.slice(indexOfFirstJob, indexOfLastJob);

  // Total pages
  const totalPages = Math.ceil((filteredJobs?.length || 0) / itemsPerPage);

  return (
    <div className="jobHistoryMain p-3">
      <ReviewModal show={showModal} handleClose={handleCloseModal} />
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="searchInput">
          <input
            type="text"
            placeholder="Search here ..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="searchIconBtn">
            <button type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="#fff"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
        </div>
      </form>
      <div className="tableDiv" style={{ overflowX: "auto" }}>
        <Table bordered hover>
          <thead>
            <tr>
              <th>S. no</th>
              <th>Job Title</th>
              <th>Rate</th>
              <th>Total</th>
              <th>Status</th>
              <th>Applicants</th>
              <th>Date</th>
              <th>More</th>
            </tr>
          </thead>
          <tbody>
            {currentJobs?.map((a, i) => (
              a.status !== "completed" && (
                <tr key={i}>
                  <td className="text-center">{indexOfFirstJob + i + 1}</td>
                  <td>{a.title}</td>
                  <td>${a.budget}</td>
                  <td>${a.budget}</td>
                  <td className="text-center">
                    {a.status === "approved" ? (
                      <Badge pill bg="primary">
                        Approved
                      </Badge>
                    ) : a.status === "completed" ? (
                      <Badge pill bg="success">
                        Completed
                      </Badge>
                    ) : a.status === "rejected" ? (
                      <Badge pill bg="danger">
                        Rejected
                      </Badge>
                    ) : a.status === "hired" ? (
                      <Badge pill bg="info">
                        Hired
                      </Badge>
                    ) : (
                      <Badge pill bg="secondary">
                        Pending
                      </Badge>
                    )}
                  </td>
                  <td className="text-center">
                    <NavLink to={`/user/job/applicants/${a._id}`}>
                      {a?.applications?.length}
                    </NavLink>
                  </td>
                  <td>{a.createdAt.split("T")[0]}</td>
                  <td className="text-center">
                    <div className="btn-div">
                      {a.status === "completed" ? (
                        <button className="primary">Completed</button>
                      ) : a.status === "hired" || a.status === "completed" ? (
                        <>
                          <button
                            className="primary"
                            onClick={() => completeJob(a)}
                          >
                            Complete
                          </button>
                          <br />
                          <button
                            className="outline mt-1"
                            onClick={() => {
                              navigate(`/chat/${a._id}`);
                            }}
                          >
                            Chat
                          </button>
                        </>
                      ) : (
                        <NavLink to={`/user/job/applicants/${a._id}`}>
                          <button className="primary">Hire</button>
                        </NavLink>
                      )}
                    </div>
                  </td>
                </tr>
              )
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-end mt-3">
        <Pagination className="custom-pagination">
          <Pagination.Prev
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
          />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
          />
        </Pagination>
      </div>
    </div>
  );
}

export default OngoingJobs;
