import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, patch, post, postWithoutToken } from "../../apiService";

export const getUser = createAsyncThunk(
  "getUser",
  (id) =>
    new Promise((resolve, reject) => {
      let url = `/user/${id}`;
      resolve(get(url));
    })
);

export const reportUser = createAsyncThunk(
  "reportUser",
  (data) =>
    new Promise((resolve, reject) => {
      let url = `/complaint/`;
      resolve(post(url, { data }));
    })
);

export const createPaymentIntent = createAsyncThunk(
  "createPaymentIntent",
  (data) =>
    new Promise((resolve, reject) => {
      let url = `payment/create-payment-intent`;
      resolve(post(url, { data }));
    })
);

export const createWithdrawRequest = createAsyncThunk(
  "createWithdrawRequest",
  (data) =>
    new Promise((resolve, reject) => {
      let url = `withdrawrequests/create-withdraw-request`;
      resolve(post(url,  data ));
    })
);

export const getWithdrawRequests = createAsyncThunk(
  "getWithdrawRequests",
  () =>
    new Promise((resolve, reject) => {
      let url = `withdrawrequests/get-withdraw-requests`;
      resolve(get(url));
    })
);

export const purchaseSubscription = createAsyncThunk(
  "purchaseSubscription",
  (data) =>
    new Promise((resolve, reject) => {
      let url = `payment/purchase-subscription`;
      resolve(post(url, { data }));
    })
);