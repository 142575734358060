import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import DashboardDrawer from "./Common/dashboardDrawer/DashboardDrawer";
import Layout from "./Layout";
import SubcriptionScreen from "./Screens/SubcriptionScreen/SubscriptionScreen";

function DashboardLayout(props) {
  let user = useSelector((state) => state.auth.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.name]);

  useEffect(() => {
    console.log(user)
  }, [user])

  return (
    <Layout>
      <div className="portalLayoutMain pb-5">
        <div className="banner-sec mb-5">
          <div className="container d-flex align-items-center justify-content-center h-100">
            <div className="heading">
              <h1>{props.name}</h1>
            </div>
          </div>
        </div>

        <Container>
          <Row>
            <Col md={3}>
              <DashboardDrawer defaultActive={props.defaultActive} />
            </Col>
            <Col md={9}>
              {user?.subscribed ?
                props.children
                :
                <SubcriptionScreen />
              }
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default DashboardLayout;
