import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, patch, post } from "../../apiService";

export const createJob = createAsyncThunk(
  "job",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(post(`job`, payload));
    })
);
export const createManyJob = createAsyncThunk(
  "job",
  ({ payload }) =>
    new Promise((resolve, reject) => {
      resolve(post(`job/createjobsmany`, payload));
    })
);

export const getAllJobs = createAsyncThunk(
  "getAllJobs",
  (payload) =>
    new Promise((resolve, reject) => {
      let url = "job/getpostedjobs?";
      if (payload) {
        for (let keys in payload) {
          url += `${keys}=${payload[keys]}`;
        }
      }
      resolve(get(url));
    })
);

export const getAllFrontendJobs = createAsyncThunk(
  "getAllFrontendJobs",
  ({ category, page, limit }) =>
    new Promise((resolve, reject) => {
      const url = `job?category=${category}&page=${page}&limit=${limit}`;
      resolve(get(url));
    })
);

export const getPostedJobs = createAsyncThunk(
  "getPostedJobs",
  (payload) =>
    new Promise((resolve, reject) => {
      let url = "job/getpostedjobs";
      resolve(get(url));
    })
);

export const getSingleJobWithApplicants = createAsyncThunk(
  "getSingleJobWithApplicants",
  (id) =>
    new Promise((resolve, reject) => {
      let url = `job/getjobwithapplicants/${id}`;
      resolve(get(url));
    })
);

export const getJobsWithApplicantsInProgress = createAsyncThunk(
  "getJobsWithApplicantsInProgress",
  () =>
    new Promise((resolve, reject) => {
      let url = `job/getJobsWithApplicantsInProgress/`;
      resolve(get(url));
    })
);

export const getCompanionChats = createAsyncThunk(
  "getChatsCompanion",
  () =>
    new Promise((resolve, reject) => {
      let url = `job/getChatsCompanion/`;
      resolve(get(url));
    })
);

export const completeUserJob = createAsyncThunk(
  "completeUserJob",
  ({ job, companion }) =>
    new Promise((resolve, reject) => {
      resolve(patch(`job/completejob/${job}`, { id: companion }));
    })
);

export const createReview = createAsyncThunk(
  "createReview",
  (payload) =>
    new Promise((resolve, reject) => {
      resolve(post(`review`, payload));
    })
);

export const getUserReviews = createAsyncThunk(
  "reviews",
  ({ page, limit, id }) =>
    new Promise((resolve, reject) => {
      resolve(get(`review/${id}?page=${page}&limit=${limit}`));
    })
);

export const getChats = createAsyncThunk(
  "getChats",
  (id) =>
    new Promise((resolve, reject) => {
      resolve(get(`chat/getChatHistory/${id}`));
    })
);

export const sendMessage = createAsyncThunk(
  "sendMessage",
  (payload) =>
    new Promise((resolve, reject) => {
      resolve(post(`chat/sendMessage`, payload));
    })
);
