import React, { useEffect, useState } from "react";

import { Accordion, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./DashboardDrawer.scss";

export default function DashboardDrawer(props) {
  const [activeAccordion, setActiveAccordion] = useState("1");

  let user = useSelector((state) => state.auth.user);

  const handleAccordionClick = (eventKey) => {
    setActiveAccordion(activeAccordion === eventKey ? "" : eventKey);
  };

  const CustomAccordionToggle = ({ children, eventKey, callback }) => {
    const onClick = (e) => {
      e.preventDefault();
      callback(eventKey);
    };

    return (
      <Nav.Link
        className="d-flex align-items-center justify-content-between"
        href="#"
        onClick={onClick}
      >
        {children}
      </Nav.Link>
    );
  };

  useEffect(() => {
    setActiveAccordion(props.defaultActive);
  }, []);

  const CollapseItem = ({ name, link }) => {
    return (
      <Nav.Item>
        <NavLink className="px-4 d-flex align-items-center gap-2" to={link}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
            />
          </svg>
          {name}
        </NavLink>
      </Nav.Item>
    );
  };

  return (
    <div className="dDrawerMain shadow p-2">
      <div className="imgDiv">
        <div
          className="bgProfile shadow"
          style={user?.image ? { backgroundImage: `url(${user.image})` } : {}}
        ></div>
      </div>
      <div className="heading text-center mt-4">
        <h5>{user?.name}</h5>
        <i>Identity: {!user?.identityVerified ? "Not Verified" : "Verified"}</i>
        <br />
        <i>Subscription: {!user?.subscribed ? "Not Puchased" : "Puchased"}</i>
        <h6>Email: {user?.email}</h6>
      </div>

      <div className="navMain">
        <Nav className="flex-column">
          <Nav.Item>
            <NavLink to="/user/dashboard">Dashboard</NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink to="/user/profile-setting">Profile</NavLink>
          </Nav.Item>

          {user?.role !== "companion" && (
            <Nav.Item>
              <NavLink to="/user/purchase-credit">Purchase Credits</NavLink>
            </Nav.Item>
          )}

          <Nav.Item>
            <Accordion
              activeKey={activeAccordion}
              defaultActiveKey={props.defaultActive}
            >
              <CustomAccordionToggle
                eventKey="1"
                callback={handleAccordionClick}
              >
                Jobs
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-double-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </CustomAccordionToggle>
              <Accordion.Collapse eventKey="1">
                <Nav className="flex-column ml-3">
                  {user?.role !== "companion" && (
                    <CollapseItem name="Create Job" link="/user/job/create" />
                  )}
                  {user?.role !== "companion" && (
                    <CollapseItem name="Jobs Request" link="/user/job/history" />
                  )}
                  {user?.role === "companion" && (
                    <CollapseItem name="Jobs History" link="/user/job/history" />
                  )}

                  {/* <CollapseItem name="Jobs History" link="/user/job/history" /> */}
                  <CollapseItem
                    name="Finished Jobs"
                    link="/user/job/finished"
                  />
                  {user?.role === "companion" && (
                    <CollapseItem
                      name="Applied Jobs"
                      link="/user/job/applied"
                    />
                  )}
                  {user?.role === "companion" && (
                    <CollapseItem
                      name="Jobs In Progress"
                      link="/user/job/in-progress"
                    />
                  )}
                  {user?.role === "loved-one" && (
                    <CollapseItem
                      name="Ongoing Jobs"
                      link="/user/job/ongoing-jobs"
                    />
                  )}
                </Nav>
              </Accordion.Collapse>
            </Accordion>
          </Nav.Item>

          {/* <Nav.Item>
            <Accordion
              activeKey={activeAccordion}
              defaultActiveKey={props.defaultActive}
            >
              <CustomAccordionToggle
                eventKey="2"
                callback={handleAccordionClick}
              >
                Deposit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-double-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </CustomAccordionToggle>
              <Accordion.Collapse eventKey="2">
                <Nav className="flex-column ml-3">
                  <CollapseItem name="Deposit Now" link="/user/deposit/now" />
                  <CollapseItem
                    name="Deposit History"
                    link="/user/deposit/history"
                  />
                </Nav>
              </Accordion.Collapse>
            </Accordion>
          </Nav.Item> */}

          {user?.role === "companion" &&
            <Nav.Item>
              <Accordion
                activeKey={activeAccordion}
                defaultActiveKey={props.defaultActive}
              >
                <CustomAccordionToggle
                  eventKey="3"
                  callback={handleAccordionClick}
                >
                  Withdraw
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-double-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </CustomAccordionToggle>
                <Accordion.Collapse eventKey="3">
                  <Nav className="flex-column ml-3">
                    <CollapseItem name="Withdraw Now" link="/user/withdraw/now" />
                    <CollapseItem
                      name="Withdraw History"
                      link="/user/withdraw/history"
                    />
                  </Nav>
                </Accordion.Collapse>
              </Accordion>
            </Nav.Item>
          }

          <Nav.Item>
            <NavLink to="/user/transactions">Transactions</NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink to="/user/change-password">Change Password</NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink to="/user/identity-verification">
              Identity Verification
            </NavLink>
          </Nav.Item>
        </Nav>
      </div>
    </div>
  );
}
