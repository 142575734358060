import { TimePicker } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Calendar } from "rsuite";
import "rsuite/Calendar/styles/index.css";
import { showErrorToast } from "../../../apiService";
import { createJob, createManyJob } from "../../../redux/jobSlice/actions";
import "./CreateJob.css";

// Date format
const format = "MM/DD/YYYY";

function CreateJob() {
  const user = useSelector((state) => state.auth.user);
  let { wallet } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [date, setDate] = useState("");
  const [formData, setFormData] = useState({
    category: "Household Help",
    title: "",
    budget: "",
    totalBudget: "",
    totalBudget: "",
    type: "Fixed",
    jobtype: "Single",
    city: "",
    state: "",
    zipcode: "",
    address: "",
    description: "",
    date: date,
    time: "",
    scheduling: [],
  });

  const [dates, setDates] = useState([]);
  const [modalData, setModalData] = useState({
    category: "Household Help",
    title: "",
    budget: "",
    type: "Fixed",
    description: "",
    time: "",
    date: date,
    city: "",
    state: "",
    zipcode: "",
    address: "",
    date: "",
    totalBudget: "",
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      dates: dates.map((date) => date.format(format)),
    }));
  }, [dates]);

  const handleTimeChange = (times) => {
    if (times.isValid()) {
      const formattedTime = dayjs(times).format("HH:mm:ss");
      setFormData((prev) => ({ ...prev, time: formattedTime }));
    }
  };
  const handleTimeModalChange = (times) => {
    if (times.isValid()) {
      const formattedTime = dayjs(times).format("HH:mm:ss");
      setModalData((prev) => ({ ...prev, time: formattedTime }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const postJob = (e) => {
    e.preventDefault();
    if (!user?.identityVerified) {
      showErrorToast("To apply, please confirm your identity.");
      return; // Make sure to exit if identity is not verified
    }

    console.log("Job Type:", formData.jobtype); // Debugging line

    if (formData.jobtype === "Scheduling") {
      console.log("Dispatching createManyJob");
      dispatch(createManyJob({ payload: { jobs: formData.scheduling } })).then(
        (res) => {
          if (res.payload?.status === "success") {
            clearForm();
          }
        }
      );
    } else {
      console.log("Dispatching createJob");
      dispatch(createJob({ payload: formData })).then((res) => {
        if (res.payload?.status === "success") {
          clearForm();
        }
      });
    }
  };

  const clearForm = () => {
    setFormData({
      category: "",
      title: "",
      budget: "",
      totalBudget: "",
      type: "",
      jobtype: "",
      city: "",
      state: "",
      zipcode: "",
      address: "",
      description: "",
      scheduling: [],
      date: "",
      time: "",
    });
    setDates([]);
  };

  const handleDateClick = (dates) => {
    console.log("Selected date:", date);
    setDate(dates);
    setModalVisible(true);
  };
  const handleSingleDateClick = (dates) => {
    console.log("Selected date:", date);
    setFormData((prev) => ({
      ...prev,
      date: dates,
    }));
    setDate(dates);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setModalData({
      category: "",
      title: "",
      budget: "",
      type: "",
      description: "",
      totalBudget: "",
    });
  };
  console.log(formData.jobtype);

  const handleModalInputChange = (event) => {
    const { name, value } = event.target;
    setModalData({ ...modalData, [name]: value });
  };

  const handleAddressChange = (event) => {
    const { name, value } = event.target;

    // Update formData
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Update existing scheduling entries
    setFormData((prev) => ({
      ...prev,
      scheduling: prev.scheduling.map((entry) => ({
        ...entry,
        [name]: value, // Update the specific field in each scheduling object
      })),
    }));
  };

  const handleConfirm = () => {
    const newEntry = {
      category: modalData.category,
      title: modalData.title,
      budget: modalData.budget,
      type: modalData.type,
      description: modalData.description,
      city: modalData.city,
      state: modalData.state,
      zipcode: modalData.zipcode,
      address: modalData.address,
      date: date,
      time: modalData.time,
      totalBudget: modalData.totalBudget,
    };

    // Update the scheduling array
    setFormData((prevData) => ({
      ...prevData,
      scheduling: [...prevData.scheduling, newEntry], // Add the new entry
    }));
    setModalVisible(false);
    setModalData({
      category: "",
      title: "",
      budget: "",
      type: "Fixed",
      descripion: "",
      time: "",
      totalBudget: "",
    });
  };
  const getTotalSchedulingAmount = () => {
    return formData.scheduling.reduce(
      (total, job) => total + parseFloat(job.budget || 0),
      0
    );
  };

  const totalSchedulingAmount = getTotalSchedulingAmount();
  const calculatePercentage = (budget) => {
    if (budget) {
      return budget * 0.05;
    }
    return 0;
  };

  // Update the formData state
  useEffect(() => {
    const percentage = calculatePercentage(formData.budget);
    setFormData((prev) => ({
      ...prev,
      totalBudget: Number(formData.budget) + percentage, // Keeping two decimal places
    }));
  }, [formData.budget]); // Runs whenever the budget changes

  const calculateModalPercentage = (budget) => {
    if (budget) {
      return budget * 0.05;
    }
    return 0;
  };

  // Update the formData state
  useEffect(() => {
    const percentage = calculateModalPercentage(modalData.budget);
    setModalData((prev) => ({
      ...prev,
      totalBudget: Number(modalData.budget) + percentage, // Keeping two decimal places
    }));
  }, [modalData.budget]); // Runs whenever the budget changes
  return (
    <div className="createJobMain shadow p-3">
      <div className="formDiv">
        <form onSubmit={(e) => (user?.subscribed ? postJob(e) : null)}>
          <div className="inputDiv">
            <label>
              Job Type<sup className="text-red">*</sup>
            </label>
            <select
              value={formData.jobtype}
              onChange={handleInputChange}
              name="jobtype"
              required
            >
              <option disabled>Select Type</option>
              <option>Single</option>
              <option>Scheduling</option>
            </select>
          </div>
          {formData.jobtype === "Single" || formData.jobtype === "" ? (
            <>
              <div className="inputDiv">
                <label>
                  Category<sup className="text-red">*</sup>
                </label>
                <select
                  value={formData.category}
                  onChange={handleInputChange}
                  name="category"
                  required
                >
                  <option disabled>Select Category</option>
                  <option>Household Help</option>
                  <option>Healthcare Appointment</option>
                  <option>Salon/SPA Appointment</option>
                  <option>Grocery Runs</option>
                  <option>Travel Companions</option>
                  <option>Personal Driver</option>
                  <option>Lively-hood & Exercise</option>
                  <option>Shopping</option>
                  <option>Other</option>
                </select>
              </div>
              <div className="inputDiv">
                <label>
                  Job Title<sup className="text-red">*</sup>
                </label>
                <input
                  value={formData.title}
                  onChange={handleInputChange}
                  name="title"
                  type="text"
                  required
                />
              </div>

              <Row>
                <Col md={4}>
                  <div className="inputDiv">
                    <label>
                      Amount<sup className="text-red">*</sup>
                    </label>
                    <input
                      value={formData.budget}
                      onChange={handleInputChange}
                      name="budget"
                      type="number"
                      required
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="inputDiv">
                    <label>
                      You Will Be Charged Plus (5%)
                      <sup className="text-red">*</sup>
                    </label>
                    <input
                      value={formData.totalBudget}
                      // onChange={handleInputChange}
                      name="totalBudget"
                      type="number"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="inputDiv">
                    <label>
                      Job Type<sup className="text-red">*</sup>
                    </label>
                    <select
                      value={formData.type}
                      onChange={handleInputChange}
                      name="type"
                      required
                    >
                      <option>Fixed</option>
                      {/* <option>Hourly</option> */}
                    </select>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          {formData.jobtype === "Scheduling" ? (
            <div className="inputDiv">
              <label>
                Scheduling<sup className="text-red">*</sup>
              </label>

              <Calendar
                bordered
                cellClassName={(date) =>
                  date.getDay() % 2 ? "bg-gray" : undefined
                }
                onSelect={handleDateClick}
              />
            </div>
          ) : (
            <> </>
          )}
          <Row>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  City<sup className="text-red">*</sup>
                </label>
                <input
                  value={formData.city}
                  onChange={
                    formData.jobtype === "Scheduling"
                      ? handleAddressChange
                      : handleInputChange
                  }
                  name="city"
                  type="text"
                  required
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  State<sup className="text-red">*</sup>
                </label>

                <input
                  name="state"
                  type="text"
                  value={formData.state}
                  onChange={
                    formData.jobtype === "Scheduling"
                      ? handleAddressChange
                      : handleInputChange
                  }
                  required
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="inputDiv">
                <label>
                  Zip Code<sup className="text-red">*</sup>
                </label>
                <input
                  name="zipcode"
                  type="number"
                  value={formData.zipcode}
                  onChange={
                    formData.jobtype === "Scheduling"
                      ? handleAddressChange
                      : handleInputChange
                  }
                  required
                />
              </div>
            </Col>
          </Row>
          <div className="inputDiv">
            <label>
              Address<sup className="text-red">*</sup>
            </label>
            <textarea
              value={formData.address}
              onChange={
                formData.jobtype === "Scheduling"
                  ? handleAddressChange
                  : handleInputChange
              }
              name="address"
              rows={5}
              required
            />
          </div>
          {formData.jobtype === "Single" || formData.jobtype === "" ? (
            <div className="inputDiv">
              <label>
                Job Description<sup className="text-red">*</sup>
              </label>
              <textarea
                value={formData.description}
                onChange={handleInputChange}
                name="description"
                rows={6}
                required
              />
            </div>
          ) : (
            <></>
          )}

          {formData.jobtype === "Single" || formData.jobtype === "" ? (
            <>
              <div className="inputDiv">
                <label>
                  Time<sup className="text-red">*</sup>
                </label>
                <TimePicker
                  // value={formData.time}
                  onChange={handleTimeChange}
                  size="large"
                />
              </div>
              <div className="inputDiv">
                <label>
                  Scheduling<sup className="text-red">*</sup>
                </label>

                <Calendar
                  bordered
                  cellClassName={(date) =>
                    date.getDay() % 2 ? "bg-gray" : undefined
                  }
                  onSelect={handleSingleDateClick}
                />
              </div>
            </>
          ) : (
            <> </>
          )}
          {formData.jobtype === "Scheduling" ? (
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Title</th>
                    <th>Earn</th>
                    <th>Job Type</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.scheduling?.map((e, index) => (
                    <tr key={index}>
                      <td>{e.category}</td>
                      <td>{e.title}</td>
                      <td>{e.budget}</td>
                      <td>{e.type}</td>
                      <td>{new Date(e.date).toLocaleDateString()}</td>
                      <td>{e.time}</td>
                      <td className="description-cell"> {e.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <></>
          )}
          <div className="btn-div">
            {formData.jobtype === "Scheduling" ? (
              totalSchedulingAmount < wallet?.credits ? (
                <button type="submit">Submit</button>
              ) : (
                <NavLink to="/user/purchase-credit">
                  <button>Please Purchase Credits to Continue</button>
                </NavLink>
              )
            ) : formData.budget < wallet?.credits ? (
              <button type="submit">Submit</button>
            ) : (
              <NavLink to="/user/purchase-credit">
                <button>Please Purchase Credits to Continue</button>
              </NavLink>
            )}
          </div>
          <Modal show={modalVisible} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Job Scheduling</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="inputDiv">
                <label>
                  Category<sup className="text-red">*</sup>
                </label>
                <select
                  value={modalData.category}
                  onChange={handleModalInputChange}
                  name="category"
                  required
                >
                  <option disabled>Select Category</option>
                  <option>Household Help</option>
                  <option>Healthcare Appointment</option>
                  <option>Salon/SPA Appointment</option>
                  <option>Grocery Runs</option>
                  <option>Travel Companions</option>
                  <option>Personal Driver</option>
                  <option>Lively-hood & Exercise</option>
                  <option>Shopping</option>
                  <option>Other</option>
                </select>
              </div>
              <div
                className="inputDiv"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <label>
                  Job Title<sup className="text-red">*</sup>
                </label>
                <input
                  value={modalData.title}
                  onChange={handleModalInputChange}
                  name="title"
                  type="text"
                  required
                />
              </div>
              <Row>
                <Col md={12} className="mt-2">
                  <div className="inputDiv">
                    <label>
                      Amount<sup className="text-red">*</sup>
                    </label>
                    <input
                      value={modalData.budget}
                      onChange={handleModalInputChange}
                      name="budget"
                      type="number"
                      required
                    />
                  </div>
                </Col>
                <Col md={12} className="mt-2">
                  <div className="inputDiv">
                    <label>
                      You Will Be Charged Plus (5%)
                      <sup className="text-red">*</sup>
                    </label>
                    <input
                      value={modalData.totalBudget}
                      // onChange={handleInputChange}
                      name="totalBudget"
                      type="number"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={12} className="mt-2">
                  <div className="inputDiv">
                    <label>
                      Job Type<sup className="text-red">*</sup>
                    </label>
                    <select
                      value={modalData.type}
                      onChange={handleModalInputChange}
                      name="type"
                      required
                    >
                      <option>Fixed</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <div className="inputDiv mt-2" >
                <label>
                  Time<sup className="text-red">*</sup>
                </label>
                <TimePicker
                  // value={formData.time}
                  onChange={handleTimeModalChange}
                  size="large"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                />
              </div>
              <div
                className="inputDiv"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <label>
                  Job Description<sup className="text-red">*</sup>
                </label>
                <textarea
                  value={modalData.description}
                  onChange={handleModalInputChange}
                  name="description"
                  rows={6}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleConfirm}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </form>
      </div>
    </div>
  );
}

export default CreateJob;
